<template>
  <div class="mt-5 pt-4">
    <div class="d-flex j-center">
      <div
        class="row pt-3"
        style="max-width:900px"
      >
        <div class="col-12 d-flex j-center">
          <img
            class="card-img-top"
            src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg"
            style="max-height:200px"
            alt="Card image cap"
          >
        </div>
        <div class="col-12 col-md-6">
          <h3 class="text-center mt-2 mb-0">
            {{ Event.name }}
          </h3>
          <p
            class="p-2"
          >
            {{ Event.date.day }} /
            {{ Event.date.month }} /
            {{ Event.date.year }}
          </p>
          <p
            v-if="Event.address"
            class="lead"
          >
            <mdb-icon icon="map-marker-alt" />
            {{ Event.address.line }},
            {{ Event.address.street }},
            {{ Event.address.city.name }},
            {{ Event.address.city.state.name }},
            {{ Event.address.city.state.country.name }}.
          </p>
        </div>
        <div class="col-12 col-md-6">
          <p class="p-2 text-justify">
            {{ Event.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mdbIcon} from 'mdbvue';
import gql from 'graphql-tag';
import eventGql from '../../graphql/event.gql';
export default {
  name: 'Evento',
  components: {
    mdbIcon,
  },
  data() {
    return {
      Event: {
        date: {},
      },
    };
  },
  apollo: {
    Event() {
      return {
        query: gql`${eventGql}`,
        variables: {
          id: this.$route.params.id,
        },
        update: (data) => data.Event[0],
      };
    },
  },
};
</script>
